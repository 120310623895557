
const envs = {
  development: {
    api_prefix: process.env.REACT_APP_API_PREFIX || "http://localhost:3000/admin_api/v1",
    api_logging: true,
  },
  staging: {
    api_prefix: "https://staging.okazuhime.info/admin_api/v1",
    api_logging: true,
  },
  production: {
    api_prefix: "https://okazuhime.info/admin_api/v1",
    api_logging: false,
  },
}

// const env = process.env.REACT_APP_RELEASE_ENV ?
//   envs[process.env.REACT_APP_RELEASE_ENV] :
//   envs['development']
const env_name = process.env.REACT_APP_RELEASE_ENV ? process.env.REACT_APP_RELEASE_ENV : 'development'
const env = {...envs[env_name], name: env_name}

export default env