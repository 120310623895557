import React, {useState} from 'react'
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell'

import {normalizePath} from 'modules/rvadmin/utils/Util'
import Api from 'modules/rvadmin/utils/Api'
import useFlash from 'modules/rvadmin/core/useFlash'
import { Wrapper } from 'components/organisms/Wrap'
import newRowsPager from 'modules/mui-binder/newRowsPager'
import ButtonResourceStatus from 'components/atoms/ButtonResourceStatus'
import PublishStatus from 'components/molecules/PublishStatus'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button'

import { formatDistance } from 'date-fns'
import StatusTranscode from 'components/atoms/StatusTranscode'
import ButtonTranscodeStatus from 'components/atoms/ButtonTranscodeStatus'

export default ({match}) => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)

  const itemPager = newRowsPager({
    headers: ['Slug', 'サムネイル', '名前', '作成', 'action'],
    onPage: ({offset, limit, updateRows}) => {
      // console.log('offlimit', offset, limit)
      const params = {
        query: {offset, limit}
      }
      setLoading(true)
      Api.fetchAuth('/movies', params).then(r=>r.json()).then(response=>{
        updateRows(response.data, response.total)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row, updateRowById}) => {
      return (
        <React.Fragment>
          <TableCell>
            <Link href={`/movies/${row.id}`}>
              {row.slug}
            </Link>
          </TableCell>
          <TableCell>{row.thumbnail_url && <img width="100" src={row.thumbnail_url} />}</TableCell>
          <TableCell>
            {row.title}<br/>
            {row.filename}
          </TableCell>
          <TableCell>{formatDistance(new Date(row.created_at), new Date(), { addSuffix: true})}</TableCell>
          <TableCell><ButtonTranscodeStatus size="small" {...{...row, updateRowById}} /></TableCell>
        </React.Fragment>
      )
    }
  })
  
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            Movie 一覧
          </Grid>
          <Grid item>
            <Button href={'/movies/upload'} variant="contained" color="default"
              size="small" startIcon={<AddCircleIcon />}>
              Upload
            </Button>
          </Grid>
        </Grid>
      </Typography>
      
      {loading && <CircularProgress size={20} />}
      {itemPager.render}
    </Wrapper>
  )
}
