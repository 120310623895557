import React, {useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SideMenu from './SideMenu';
import useSuperCache from 'modules/rvadmin/core/useSuperCache'

import AuthToken from 'modules/rvadmin/utils/AuthToken'
import Env from 'modules/rvadmin/utils/Env'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'(c) Reivo.Inc'}
    </Typography>
  );
}

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    '&>a': {
      color: theme.palette.grey[900],
    }
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default ({children}) => {
  // console.log('AuthToken', AuthToken.data)
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const {me} = useSuperCache()
  const {permission, party} = me
  const service = AuthToken.service
  const {ability_kind} = permission
  const site_name = party ? party.name : service.display_name
  const site_url = (service && service.primary_domain) ? `//${service.primary_domain}` : null

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <SiteTitle name={site_name} url={site_url} />
          
          {/*<IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>*/}
          <AccountMenu me={me} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <SideMenu />
        </List>
        
        <Divider />
        <List><SecondaryListItems /></List>
        
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
        <Copyright />
      </main>
    </div>
  );
}

const AccountMenu = ({me}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const onLogout = () => {
    localStorage.clear()
    window.location.href = '/signin'
  }

  return (
    <React.Fragment>
      <IconButton color="inherit" onClick={(e)=>setAnchorEl(e.currentTarget)}>
        <PersonIcon />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={()=>setAnchorEl(null)}>
        <MenuItem onClick={()=>{}}>{me.staff.email}</MenuItem>
        <MenuItem component="a" href="/account/permissions">
          <SwapHorizIcon style={{paddingRight: 6}} /> アカウントを変更
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ExitToAppIcon style={{paddingRight: 6}} /> ログアウト
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

const SiteTitle = ({name, url}) => {
  const classes = useStyles();

  const renderLink = () => {
    return (
      <IconButton size="small" color="inherit" component="a" target="_blank" href={url}>
        <OpenInNewIcon />
      </IconButton>
    )
  }
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          <Link href='/'>{name}</Link>
        </Typography>
      </Grid>
      <Grid item>
        <Chip size="medium" label={Env.name} color={Env.name==='production' ? 'secondary' : 'default'} />
      </Grid>
      {url && <Grid item>
        {renderLink()}
      </Grid>}
    </Grid>
  )
}


const SecondaryListItems = () => {
  return (
    <div>
      {/*<ListSubheader inset>Shortcut</ListSubheader>*/}
      {/*<ListItem button component="a" href='/docs/ja/overview.html'>
        <ListItemIcon>
          <Icon>book</Icon>
        </ListItemIcon>
        <ListItemText primary="Document" />
      </ListItem>*/}
    </div>
  )
}
