import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Api from 'modules/rvadmin/utils/Api'
import useFlash from 'modules/rvadmin/core/useFlash'
import LoopIcon from '@material-ui/icons/Loop';

export default ({id, transcode_state, updateRowById, ...props}) => {
  const color = transcode_state === 'aborted' ? 'secondary' :
    (transcode_state === 'completed' ? 'primary' : 'default')
  const {handleApiError, enqueueSnackbar} = useFlash()
  const onCheck = () => {
    Api.fetchAuth(`/movies/${id}/check`, {method: 'PUT'}).then(r=>r.json()).then(response=>{
      updateRowById(response.data)
      const state = response.data.transcode_state
      if (state === 'completed') {
        enqueueSnackbar("動画処理がすべて完了しました", {variant: 'success'})
      } else if (state === 'aborted') {
        enqueueSnackbar("動画処理はエラーで中断されました。アップロードからやり直すか運営者にお問い合わせください", {variant: 'error'})
      } else {
        enqueueSnackbar("動画はまだ配信準備中です", {variant: 'warning'})
      }
    }).catch(handleApiError)
  }
  return (
    <Button 
      disabled={transcode_state === 'completed'}
      color={color}
      onClick={onCheck} 
      variant="contained" 
      startIcon={<LoopIcon />}
      {...props}>{transcode_state}</Button>
  )
}