import React, {useState,useEffect,useCallback} from 'react'
import { 
  BrowserRouter as Router, 
  Route, Redirect, Switch 
} from "react-router-dom"
import CircularProgress from '@material-ui/core/CircularProgress'
import useSuperCache, {SuperCacheProvider} from 'modules/rvadmin/core/useSuperCache'
import {FlashProvider} from 'modules/rvadmin/core/useFlash'
import ThemeProvider from './ThemeProvider'
import Dashboard from 'components/organisms/Dashboard'
import Standalone from 'components/organisms/Standalone'
import DashboardPage from 'pages/DashboardPage'
import AccountPermissionsPage from 'pages/Account/PermissionsPage'

import LabelsNewPage from 'pages/Labels/NewPage'
import LabelsEditPage from 'pages/Labels/EditPage'
import LabelsIndexPage from 'pages/Labels/IndexPage'

/* PLOP_INJECT_IMPORT */
import {pages as MoviePages} from 'pages/Movie';

export default (props) => {
  return (
    <SuperCacheProvider>
      <Main {...props} />
    </SuperCacheProvider>
  )
}

export const Main = ({me}) => {
  const [loading, setLoading] = useState(true)
  const {updateMe} = useSuperCache()
  useEffect(()=>{
    updateMe(me)
    setLoading(false)
  }, [me])
  const {permission} = me

  const renderBaseRoute = useCallback(()=>{
    if (permission) {
      return (<Dashboard>{PermittedRoute({me})}</Dashboard>)
    } else {
      return (<Standalone>{UnPermittedRoute({me})}</Standalone>)
    }
  }, [permission])

  if (loading) return (<CircularProgress />)
  return (
    <Router>
      <ThemeProvider>
        <FlashProvider>
          {renderBaseRoute()}
        </FlashProvider>
      </ThemeProvider>
    </Router>
  );
}

const PermittedRoute = ({me}) => {
  return (
    <Switch>
      <Route path="/account/permissions" exact component={AccountPermissionsPage} />
      <Route path="/" exact component={DashboardPage} />

      <Route path="/labels/new" exact component={LabelsNewPage} />
      <Route path="/labels/new/*" exact component={LabelsNewPage} />
      <Route path="/labels/prefix/*" exact component={LabelsIndexPage} />
      <Route path="/labels/:id" exact component={LabelsEditPage} />
      <Route path="/labels" exact component={LabelsIndexPage} />

      {/* PLOP_INJECT_ROUTE */}
      { MoviePages.map((x,i)=><Route key={i} path={x.path} exact component={x.component} />)}
      { MoviePages.map((x,i)=><Route key={i} path={x.path} exact component={x.component} />)}
      { MoviePages.map((x,i)=><Route key={i} path={x.path} exact component={x.component} />)}

      <Route render={props=><Redirect to="/" />} />
    </Switch>
  )
}

const UnPermittedRoute = ({me}) => {
  return (
    <Switch>
      <Route path="/account/permissions" exact component={AccountPermissionsPage} />
      <Route render={props=><Redirect to="/account/permissions" />} />
    </Switch>
  )
}