/* PLOP_INJECT_IMPORT */
import MovieEditPage from './EditPage';
import MovieIndexPage from './IndexPage';
import MovieUploadPage from './UploadPage';

export const pages = [
  /* PLOP_INJECT_EXPORT */
  {
    name: "MovieUploadPage",
    component: MovieUploadPage,
    path: '/movies/upload',
    title: 'Movie',
    icon: '',
  },
  {
    name: "MovieEditPage",
    component: MovieEditPage,
    path: '/movies/:id',
    title: 'Movie',
    icon: '',
  },
  {
    name: "MovieIndexPage",
    component: MovieIndexPage,
    path: '/movies',
    title: 'Movie',
    icon: '',
  },
]

export default {
  ...pages.reduce((acc,i)=>{acc[i.name] = i.component; return acc}, {}),
};