import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// import useSuperCache from 'modules/useSuperCache'

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//   },
//   title: {
//     display: 'flex',
//     alignItems: 'center',
//     flexGrow: 1,
//     '& svg': {
//       marginRight: theme.spacing(1),
//     }
//   },
//   content: {
//     flexGrow: 1,
//     height: '100vh',
//     overflow: 'auto',
//   },
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//   },
//   fixedHeight: {
//     height: 240,
//   },
//   seeMore: {
//     marginTop: theme.spacing(3),
//   },
// }));

export default () => {
  // const {me} = useSuperCache()
  // const {party} = me
  // const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
      </Grid>
      <Grid item xs={12} md={4}>
      </Grid>
      <Grid item xs={12} md={4}>
      </Grid>

      <Grid item xs={12}>
      </Grid>

      <Grid item xs={12}>
      </Grid>
    </Grid>
  )
}
