import React from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
import newTextField from 'modules/mui-binder/libs/newTextField'
// import HelpTip from 'components/atoms/HelpTip'

export default ({save, subject}) => {
  subject = subject || {}
  const title = newTextField({
    defaultValue: subject.title || "", label: "タイトル", 
    fullWidth: true, 
  })
  
  const body = {
    title: title.value,
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      title,
    ]} />
  )
}