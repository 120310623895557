import React, { useState } from 'react';
import SignIn from 'components/organisms/SignIn'
import Api from 'modules/rvadmin/utils/Api'
import AuthToken from 'modules/rvadmin/utils/AuthToken'
import useFlash from 'modules/rvadmin/core/useFlash'

export default () => {
  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")
  const {handleApiError} = useFlash()

  const signIn = (login, password) => {
    Api.fetch('/authenticate', {
      method: "POST",
      body: {login, password},
    }).then(r=>r.json()).then(response=>{
      const token = response.data
      AuthToken.setToken( token )
      window.location.href = '/account/permissions'
    }).catch(handleApiError)
  }

  return (
    <div>
      <SignIn
        login={login}
        password={password}
        setLogin={setLogin}
        setPassword={setPassword}
        handleSignIn={()=>signIn(login, password)} />
    </div>
  )
}
