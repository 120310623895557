import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

import Api from 'modules/rvadmin/utils/Api'
import useFlash from 'modules/rvadmin/core/useFlash'
import useUpdater from 'modules/rvadmin/core/useUpdater'
import {WrapForm, PaperPadding} from 'components/organisms/Wrap'

import Form from './parts/Form'
import StatusTranscode from 'components/atoms/StatusTranscode'
import ButtonTranscodeStatus from 'components/atoms/ButtonTranscodeStatus'
import CodeViewer from 'components/organisms/CodeViewer'

export default (props) => {
  const id = props.match.params.id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [subject, setSubject] = useState()
  const [lastUpdatedAt, update] = useUpdater()

  useEffect(()=>{
    setLoading(true)

    const api1 = Api.fetchAuth(`/movies/${id}`).then(r=>r.json())
    Promise.all([api1]).then(responses=>{
      setSubject(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })
  }, [lastUpdatedAt])

  const save = (body) => {
    console.log('body', body)
    setLoading(true)
    return Api.fetchAuth(`/movies/${id}`, {
      method: 'PUT',
      body: body
    }).then(r=>r.json()).then(response=>{
      update()
      // setLoading(false)
      // window.location.href = '/labels'
    }).catch((error)=>{
      setLoading(false)
      handleApiError(error)
    })
  }

  if (loading) return (<CircularProgress />)

  return (
    <WrapForm>
      <Typography variant="h6" gutterBottom>Movie {subject.filename}</Typography>
      <Inner {...{...subject, setSubject}} />
      <Form {...{save, subject}} />
    </WrapForm>
  )
}

const Inner = ({id, slug, filename, content_type, transcode_state, distribute_url, setSubject}) => {
  const [membership, setMembership] = useState('vip')
  const code = `<video class="okazuhime-video-${membership} vjs-16-9" controls="controls" data-src="${distribute_url}"></video>`
  return (
    <React.Fragment>
      <Grid container direction="column" spacing={2}>
        <Grid item container direction="row" spacing={1}>
          <Grid item>
            <StatusTranscode {...{transcode_state}} />
          </Grid>
          <Grid item>
            <ButtonTranscodeStatus size="small" {...{id, transcode_state, updateRowById: setSubject}} />
          </Grid>
        </Grid>
        <Grid item>
          <TextField label="ファイル名" value={filename} fullWidth disabled />
        </Grid>
        <Grid item>
          <TextField label="ファイルタイプ" value={content_type} fullWidth disabled />
        </Grid>
        {transcode_state === 'completed' && (
          <React.Fragment>
            <Grid item>
              <InputLabel shrink>以下のコードをサイトに貼り付けて表示できます。</InputLabel>
              <RadioGroup row defaultValue="end">
                <FormControlLabel
                  checked={membership === 'vip'}
                  onChange={(e)=>setMembership(e.target.value)}
                  value="vip"
                  control={<Radio color="primary" />}
                  label="VIP会員"
                  labelPlacement="end"
                />
                <FormControlLabel
                  checked={membership === 'regular'}
                  onChange={(e)=>setMembership(e.target.value)}
                  value="regular"
                  control={<Radio color="primary" />}
                  label="VIP会員 & 一般会員"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
            <Grid item>
              <CodeViewer value={code} options={{lineWrapping: true}} />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  )
}
