import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// import Icon from '@material-ui/core/Icon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import newSideMenuItem from 'modules/mui-binder/newSideMenuItem'

import AuthToken from 'modules/rvadmin/utils/AuthToken'

const useStyles = makeStyles(theme => ({
  sidemenu: {
  },
}))

export default () => {
  const classes = useStyles()

  const movie = newSideMenuItem({
    label: "動画",
    defaultValue: !! window.location.pathname.match(/\/movies/),
    icon: "movie",
    items: [
      {
        title: '一覧',
        href: `/movies`,
        icon: 'list',
      },
      {
        title: 'アップロード',
        href: `/movies/upload`,
        icon: 'add',
      },
    ],
  })

  const account = newSideMenuItem({
    label: "アカウント",
    defaultValue: !! window.location.pathname.match(/\/login_accounts/),
    icon: "lock",
    items:[
      {
        title: "一覧",
        href: '/login_accounts',
        icon: 'list',
      }
    ]
  })

  const fullItems = {
    movie, // account,
  }

  const sidemenuItems = null
  const displayItems = (!sidemenuItems) ? fullItems :
    sidemenuItems.reduce((acc,x)=>{
      acc[x] = fullItems[x]
      return acc
    }, {})


  return (
    <div className={classes.sidemenu}>
      {/*<ListItem button component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>*/}

      {/*<ListItem button component="a" href="/shared/assets">
        <ListItemIcon>
          <AttachFileIcon />
        </ListItemIcon>
        <ListItemText primary="Shared" />
      </ListItem>*/}

      {Object.values(displayItems).map((x,i)=>{
        const render = (typeof x.render !== 'undefined') ? x.render : x
        return (<React.Fragment key={i}>{render}</React.Fragment>)
      })}
    </div>
  )
}
