import React, {useState, useEffect} from 'react'
// import {useLocation} from "react-router-dom"
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import {normalizePath} from 'modules/rvadmin/utils/Util'
import Api from 'modules/rvadmin/utils/Api'
import useFlash from 'modules/rvadmin/core/useFlash'
import { WrapForm } from 'components/organisms/Wrap'

import Form from './parts/Form'

export default ({match}) => {
  const prefix = normalizePath(match.params[0])
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    setLoading(false)
  }, [])

  const save = (body) => {
    console.log('body', body)
    setLoading(true)
    return Api.fetchAuth('/labels', {
      method: 'POST',
      body: body
    }).then(r=>r.json()).then(response=>{
      setLoading(false)
      const redirect_back = prefix ? `/labels/prefix${prefix}` : `/labels`
      window.location.href = redirect_back
    }).catch((error)=>{
      setLoading(false)
      handleApiError(error)
    })
  }

  return (
    <WrapForm>
      { loading && (<CircularProgress />)}
      <Typography variant="h6" gutterBottom>ラベル新規作成</Typography>
      <Form {...{save, prefix}} />
      { loading && (<CircularProgress />)}
    </WrapForm>
  )
}
