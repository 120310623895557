import React from 'react'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/theme/neat.css'
import 'codemirror/mode/xml/xml.js'
import {Controlled as CodeMirror} from 'react-codemirror2'

export default (props) => (
  <CodeMirror {...props}
    options={{
      theme: 'material',
      readOnly: true,
      lineWrapping: true,
    }} />
)