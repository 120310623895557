import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

// import Api from 'modules/rvadmin/utils/Api'
// import useFlash from 'modules/rvadmin/core/useFlash'
import {Wrapper} from 'components/organisms/Wrap'
// import Uploader from 'modules/mui-binder/components/Uploader'
import MovieUploader from 'components/organisms/MovieUploader'

export default ({match}) => {
  
  const [loading, setLoading] = useState(true)
  

  useEffect(()=>{
    setLoading(false)
  }, [])

  return (
    <Wrapper>
      { loading && (<CircularProgress />)}
      <Typography variant="h6" gutterBottom>Movie upload</Typography>
      
      <MovieUploader />
    </Wrapper>
  )
}
