import React from 'react'
import Chip from '@material-ui/core/Chip';

export default ({transcode_state}) => {
  const color = transcode_state === 'aborted' ? 'secondary' :
      (transcode_state === 'completed' ? 'primary' : 'default')
  return (
    <Chip label={transcode_state} color={color} />
  )
}
