import decode from 'jwt-decode'

export default class AuthToken {
  static get token() {
    return localStorage.getItem("auth")
  }

  static setToken(token) {
    localStorage.setItem("auth", token)
  }

  static get data() {
    return decode(this.token)
  }

  static get service() {
    return (this.data || {}).service
  }
}