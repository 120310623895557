import React, {useState, useMemo, useCallback} from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgressWithLabel from 'components/atoms/CircularProgressWithLabel'

import Api from 'modules/rvadmin/utils/Api'
import useFlash from 'modules/rvadmin/core/useFlash'
import Uploader from 'modules/mui-binder/components/Uploader'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider'
import useUpdater from 'modules/rvadmin/core/useUpdater'

const uploadWithProgress = (url, opts={}, onProgress) => {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest()
    xhr.open(opts.method || 'get', url)
    for (const k in opts.headers||{})
      xhr.setRequestHeader(k, opts.headers[k])
    xhr.onload = e => res(e.target.responseText)
    xhr.onerror = rej;
    if (xhr.upload && onProgress)
      xhr.upload.onprogress = onProgress
    xhr.send(opts.body)
  })
}

export default ({}) => {
  const {handleApiError, enqueuePermanentError, enqueueSnackbar} = useFlash()
  const [lastUpdatedAt, update] = useUpdater()

  const lines = [
    useUploadLine({update}), useUploadLine({update}), useUploadLine({update}),
    useUploadLine({update}), useUploadLine({update}), useUploadLine({update}),
    useUploadLine({update}), useUploadLine({update}), useUploadLine({update}), useUploadLine({update}),
  ]
  const availableLines = useMemo(()=>lines.filter(x=>!x.isUsing()), [lastUpdatedAt])
  const usingLines = useMemo(()=>lines.filter(x=>x.isUsing()), [lastUpdatedAt])

  const onDrop = useCallback(files => {
    if (files.length > availableLines.length) {
      enqueuePermanentError(`同時にアップロードできる動画は${lines.length}件までです`)
      return
    }
    const arrs = files.map((x,i)=>[x, availableLines[i]])
    arrs.forEach(arr=>{
      const [file, line] = arr
      line.startUpload(file)
    })
    update()
  }, [availableLines])

  return (
    <React.Fragment>
      <Uploader onDrop={onDrop} message="ドラッグドロップで動画を追加できます" />
      <List>
        {usingLines.length > 0 && <Typography variant="body1">アップロードが完了するまでこのページから離れないでください</Typography>}
        {usingLines.map((x,i)=>
          (<React.Fragment key={i}><Line {...x} /><Divider variant="inset" component="li" /></React.Fragment>)
        )}
      </List>
    </React.Fragment>
  )
}

const Line = ({info, progress, completed}) => (
  <ListItem>
    <ListItemAvatar>
      <CircularProgressWithLabel value={progress} />
    </ListItemAvatar>
    <ListItemText primary={info.filename} secondary={info.content_type} />
    <ListItemSecondaryAction>
      {completed && <CheckCircleIcon color="primary" />}
    </ListItemSecondaryAction>
  </ListItem>
)

const useUploadLine = ({update}) => {
  const {handleApiError, enqueuePermanentError, enqueueSnackbar} = useFlash()
  
  const [info, setInfo] = useState(null)
  const [progress, setProgress] = useState(0)
  const onProgress = data => {
    console.log('progress', data, data.loaded/data.total)
    setProgress(100 * (data.loaded/data.total))
    update()
  }
  const [completed, setCompleted] = useState(false)

  const startUpload = (file) => {
    setInfo({filename: file.name, content_type: file.type})
    const upload_request_form = new FormData()
    upload_request_form.append("filename", file.name)
    upload_request_form.append("content_type", file.type)
    Api.fetchAuth('/upload_movies/init', {
      method: 'POST',
      body: upload_request_form
    }).then(r=>r.json()).then(response=>{
      console.log(response)
      const {url, fields, slug} = response
      const fd = new FormData()
      for (const [key, value] of Object.entries(fields)) {
        fd.append(key, value)
      }
      fd.append("file", file)
      uploadWithProgress(url, {
        method: 'POST',
        headers: {"accept": "multipart/form-data"},
        body: fd
      }, onProgress).then(response => {
        console.log(response)
        Api.fetchAuth(`/upload_movies/${slug}/complete`, {
          method: 'POST', body: upload_request_form
        }).then(res=>res.json()).then(response=>{
          setCompleted(true)
          update()
        }).catch(handleApiError)
      }).catch(handleApiError)
    }).catch(handleApiError)
  }

  return {
    info: info || {}, 
    progress, completed, startUpload,
    isUsing: ()=>!!info,
  }
}
